import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store/index.js';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: resolve => require.ensure([], () => resolve(require('@/views/Home')), 'Home'),
    meta:{
      title:'主页',
      needsAuth:false,
      needsLogin:false,
    }
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: resolve => require.ensure([], () => resolve(require('@/views/Blogs')), 'Blogs'),
    meta:{
      title:'博客',
      needsAuth:false,
      needsLogin:false,
    }
  },
  {
    path: "/login",
    name: "Login",
    component: resolve => require.ensure([], () => resolve(require('@/views/Login')), 'Login'),
    meta:{
      title:'登录',
      needsAuth:false,
      needsLogin:false,
    }
  },
  {
    path: "/register",
    name: "Register",
    component: resolve => require.ensure([], () => resolve(require('@/views/Register')), 'Register'),
    meta:{
      title:'注册',
      needsAuth:false,
      needsLogin:false,
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: resolve => require.ensure([], () => resolve(require('@/views/ForgotPassword')), 'ForgotPassword'),
    meta:{
      title:'忘记密码',
      needsAuth:false,
      needsLogin:false,
    }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: resolve => require.ensure([], () => resolve(require('@/views/ResetPassword')), 'ResetPassword'),
    meta:{
      title:'重置密码',
      needsAuth:false,
      needsLogin:false,
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: resolve => require.ensure([], () => resolve(require('@/views/Profile')), 'Profile'),
    meta:{
      title:'简历',
      needsAuth:false,
      needsLogin:true,
    }
  },
  {
    path: "/create-post",
    name: "CreatePost",
    component: resolve => require.ensure([], () => resolve(require('@/views/CreatePost')), 'CreatePost'),
    meta:{
      title:'创建博客',
      needsAuth:true,
      needsLogin:true,
    }
  },
  {
    path: "/post-preview",
    name: "BlogPreview",
    component: resolve => require.ensure([], () => resolve(require('@/views/BlogPreview')), 'BlogPreview'),
    meta:{
      title:'博客预览',
      needsAuth:true,
      needsLogin:true,
    }
  },
  {
    path: "/view-blog/:blogid",
    name: "ViewBlog",
    component: resolve => require.ensure([], () => resolve(require('@/views/ViewBlog')), 'ViewBlog'),
    meta:{
      title:'浏览博客',
      needsAuth:false,
      needsLogin:false,
    }
  },
  {
    path: "/edit-blog/:blogid",
    name: "BlogEdit",
    component: resolve => require.ensure([], () => resolve(require('@/views/BlogEdit')), 'BlogEdit'),
    meta:{
      title:'编辑博客',
      needsAuth:true,
      needsLogin:true,
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to,_,next)=>{
  document.title = `${to.meta.title} | 链智科技`;
  if(to.meta.needsLogin && store.state.auth.userId == null){
    next({name:'Home'});
  }
  if(to.meta.needsLogin && to.meta.needsAuth && store.state.auth.role != 'admin'){
    next({name:'Home'});
  }
  next();
})

export default router;
