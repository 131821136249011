import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default{
    state(){
        return{
            blogHTML:"Write your blog title here ...",
            blogTitle:"",
            blogPhotoName:"",
            blogPhotoFileURL:"",
            blogPhotoPreview:"",
            blogs:[],
            blogLoaded:null,
            currentComments:[]
        }
    },
    mutations,
    actions,
    getters
}