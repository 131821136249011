import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './auth/index.js';
import blogModule from './blog/index.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    editPost:null,
    showLogin:null
  },
  mutations: {
    toggleEditPost(state,payload){
      state.editPost = payload;
    },
    toggleShowLogin(state,payload){
      state.showLogin = payload.showLogin;
    }
  },
  actions: {
  },
  getters:{

  },
  modules: {
    auth:authModule,
    blog:blogModule
  }
})
