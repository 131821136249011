import axios from 'axios'
import {SERVER_API} from '../../utils/config.js';
export default{
    uploadCoverPhoto(context,payload){
        return axios.post(SERVER_API+"uploadImage",payload)
        .then(function(response){
            context.commit('updateBlogPhotoFileURL',response.data.photoFilePath)
        })
        .catch(function(error){
          console.log('catch a error inside',error)
          throw(error)
        })
    },
    uploadPhoto(_,payload){
        return axios.post(SERVER_API+"uploadImage",payload)
        .then(function(response){
            return response.data.photoFilePath
        })
        .catch(function(error){
          console.log('catch a error inside',error)
          throw(error)
        })
    },
    uploadBlog(_,payload){
        return axios.post(SERVER_API+"uploadBlog",payload)
        .then(function(response){
            return response
        })
        .catch(function(error){
          console.log('catch a error inside',error)
          throw(error)
        })
    },
    loadBlogs(context){
        return axios.get(SERVER_API+"getAllBlogs")
        .then(function(response){
            context.commit('setBlogs',response.data)
            context.commit('blogLoaded')
        })
        .catch(function(error){
          console.log('catch a error inside',error)
          throw(error)
        })
    },
    deleteBlog(context,payload){
        //先删除state里的blog
        context.commit("deleteBlog",payload);
        return axios.post(SERVER_API+"deleteBlogById",payload)
        .then(function(response){
            console.log(response)
        })
        .catch(function(error){
          console.log('catch a error inside when deleting the blog',error)
          throw(error)
        })
    },
    updateBlog(_,payload){
        return axios.post(SERVER_API+"updateBlog",payload)
        .then(function(response){
            console.log(response)
        })
        .catch(function(error){
          console.log('catch a error inside when update the blog',error)
          throw(error)
        })
    },
    addComment(context,payload){
        context.commit("addComment", payload);
        return axios.post(SERVER_API+"addComment",payload)
        .then(function(response){
            return response;
        })
        .catch(function(error){
          console.log('catch a error inside when adding the comment',error)
          throw(error)
        })
    },
    addCommentResponse(context,payload){
        context.commit("addResOfComment", payload);
        return axios.post(SERVER_API+"addCommentResponse",payload)
        .then(function(response){
            return response;
        })
        .catch(function(error){
          console.log('catch a error inside when adding the commentResponse',error)
          throw(error)
        })
    },
    getCommentByBlogId(context,payload){
        return axios.post(SERVER_API+"getCommentsByBlogId",payload)
        .then(function(response){
            
            if(response.data){
                context.commit("setCurrentComments",response.data)
            }else{
                context.commit("setCurrentComments",[])
            }
            console.log(response)
            return response
            
        })
        .catch(function(error){
          console.log('catch a error inside when get CommentByBlogId',error)
          throw(error)
        })
    }
}