export default{
    updateBlogTitle(state,payload){
        state.blogTitle = payload;
    },
    updateBlogPhotoFileURL(state,payload){
        state.blogPhotoFileURL = payload;
    },
    updateBlogHTML(state,payload){
        state.blogHTML = payload;
    },
    updateBlogPhotoName(state,payload){
        state.blogPhotoName = payload;
    },
    setBlogs(state,payload){
        state.blogs = payload;
    },
    clearBlog(state){
        state.blogTitle = "";
        state.blogPhotoFileURL = "";
        state.blogHTML = "";
    },
    blogLoaded(state){
        state.blogLoaded = true;
    },
    deleteBlog(state,payload){
        state.blogs = state.blogs.filter((blog) => blog.id != payload.blogid);
    },
    setCurrentComments(state,payload){
        state.currentComments = payload;
    },
    addComment(state,payload){
        state.currentComments.push(payload);
    },
    addResOfComment(state,payload){
        state.currentComments[payload.commentId-1].responses.push(payload);
    }
}