export default{
    setUser(state,payload){
        state.userId = payload.userId
        state.token = payload.token
        state.profileInitials = payload.profileInitials
        state.userName = payload.userName
        state.email = payload.email
        state.role = payload.role
    },
    updateUserName(state,payload){
        state.userName = payload.username;
    },
    clearUser(state){
        state.userId = null
        state.token = null
        state.profileInitials = null
        state.userName = null
        state.email = null
        state.role = null
    }
}