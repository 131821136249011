import axios from 'axios'
import {SERVER_API} from '../../utils/config.js';
export default{ 
    login(context,payload){
        return axios.post(SERVER_API+"login",{
            email: payload.email,
            password: payload.password
          })
          .then(function(response){

            console.log('response inside')
            
            //将前端的数据存储到浏览器中去
            localStorage.setItem('userId',response.data.User.id);
            localStorage.setItem('token',response.data.Token);

            context.commit('setUser',{
                    userId: response.data.User.id,
                    token : response.data.Token,
                    profileInitials:response.data.User.username.substr(0,1).toUpperCase(),
                    userName:response.data.User.username,
                    email:response.data.User.email,
                    role:response.data.User.role
            })
          })
          .catch(function(error){
            console.log('catch a error inside',error)
            throw(error)
          })
    },
    register(_,payload){
        return axios.post(SERVER_API+"register",{
            email:payload.email,
            username:payload.username,
            password:payload.password
        })
        .then(function(response){
            console.log("inside response",response)
        })
        .catch(function(error){
            console.log("catch inside error")
            throw(error)
        })
    },
    forgotPassword(_,payload){
        return axios.post(SERVER_API+"forgotPassword",{
            email:payload.email
        })
        .then(function(response){
            console.log("inside response",response)
        })
        .catch(function(error){
            console.log("catch inside error")
            throw(error)
        })
    },
    resetPassword(_,payload){
        return axios.post(SERVER_API+"resetPassword",{
            email:payload.email,
            password:payload.password,
            evpw:payload.evpw,
        })
        .then(function(response){
            console.log("inside response",response)
        })
        .catch(function(error){
            console.log("catch inside error")
            throw(error)
        })
    },
    isAuthenticated(context,payload){
        return axios.post(SERVER_API+"user",{
            token:payload.token
        })
        .then(function(response){
            context.commit('setUser',{
                userId: response.data.id,
                token : localStorage.getItem('token'),
                profileInitials:response.data.username.substr(0,1).toUpperCase(),
                userName:response.data.username,
                email:response.data.email,
                role:response.data.role
            })
            console.log(response)
            return response
        })
        .catch(function(error){
            console.log("catch inside error")
            throw(error)
        })
    },
    updateUserName(context,payload){
        return axios.post(SERVER_API+"updateUserName",{
            email:payload.email,
            username:payload.username
        })
        .then(function(response){
            context.commit('updateUserName',{
                username:payload.username,
            })
            console.log('in action',context.state);
            console.log(response)
            return response
        })
        .catch(function(error){
            console.log("catch inside error")
            throw(error)
        })
    }
}