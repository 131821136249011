export default{
    getFirstTwoBlogs(state){
        return state.blogs.slice(0,2)
    },
    getBlogCards(state){
        return state.blogs.slice(2,6)
    },
    getAllBlogs(state){
        return state.blogs
    },
    getComments(state){
        return state.currentComments;
    }
}