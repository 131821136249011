<template>
    <header>
        <nav class="container">
            <div class="branding">
                <router-link class="header" :to="{name:'Home'}">链智科技</router-link>
            </div>
            <div class="nav-links">
                <ul  v-show="!mobile">
                    <router-link class="link" :to="{name:'Home'}">主页</router-link>
                    <router-link class="link" :to="{name:'Blogs'}">博客</router-link>
                    <router-link class="link" :to="{name:'CreatePost'}" v-show="$store.state.auth.role == 'admin'">创建博客</router-link>
                    <router-link class="link" :to="{ name: 'Login' }" v-show="$store.state.showLogin">登录/注册</router-link>
                </ul>
                <div @click="toggleProfileMenu" class="profile" ref="profile" v-show="!$store.state.showLogin">
                    <span>{{$store.state.auth.profileInitials}}</span>
                    <div v-show="profileMenu" class="profile-menu" >
                      <div class="info">
                        <p class="initials">{{$store.state.auth.profileInitials}}</p>
                        <div class="right">
                          <p>
                            {{$store.state.auth.userName}}
                          </p>
                          <p> {{$store.state.auth.email}}</p>
                        </div>
                      </div>
                      <div class="options">
                        <div class="option">
                          <router-link class="option" :to="{name:'Profile'}">
                            <userIcon class="icon"></userIcon>
                            <p>用户简介</p>
                          </router-link>
                        </div>
                        <div @click="signOut" class="option">
                            <signOutIcon class="icon"></signOutIcon>
                            <p>登出</p>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </nav>
        <menuIcon @click="toggleMobileNav" class="menu-icon" v-show="mobile" ref="menuIcon"/>
        <transition name="mobile-nav">
            <ul class="mobile-nav" v-show="mobileNav" ref="sidebar">
                <router-link class="link"  :to="{name:'Home'}">主页</router-link>
                <router-link class="link"  :to="{name:'Blogs'}">博客</router-link>
                <router-link class="link"  :to="{name:'CreatePost'}" v-show="$store.state.auth.role == 'admin'">创建博客</router-link>
                <router-link v-show="$store.state.showLogin" class="link"  :to="{ name: 'Login' }">登录/注册</router-link>
            </ul>
        </transition>
    </header>
</template>

<script>

import menuIcon from '../assets/Icons/bars-regular.svg';
import userIcon from '../assets/Icons/user-alt-light.svg';
import signOutIcon from '../assets/Icons/sign-out-alt-regular.svg';

export default {
    name : 'navigation',
    components:{
        menuIcon,
        userIcon,
        signOutIcon
    },
    data(){
        return {
            mobile:null,
            mobileNav:null,
            windownWidth:null,
            profileMenu:null
        };
    },
    created(){
        window.addEventListener('resize',this.checkScreen);
        this.checkScreen();
    },
    mounted() {
      // 监听全局点击事件
      document.addEventListener("click", this.bodyCloseMenus);
    },
  
    beforeDestroy() {
      // 在页面注销前，将点击事件给移除
      document.removeEventListener("click", this.bodyCloseMenus);
    },
    methods:{
        checkScreen(){
            this.windownWidth = window.innerWidth;
            if(this.windownWidth <= 750){
                this.mobile = true;
                return;
            }
            this.mobile = false;
            this.mobileNav = false;
            return;
        },
        toggleMobileNav(){
            this.mobileNav = !this.mobileNav;
        },
        toggleProfileMenu(e){
          if(e.target === this.$refs.profile){
            this.profileMenu = !this.profileMenu;
          }
        },
        signOut(){
          localStorage.removeItem('id')
          localStorage.removeItem('token')
          this.$store.commit('toggleShowLogin',{showLogin:true});
          this.$store.commit('clearUser')
          this.$router.push({name:'Home'})
        },
        bodyCloseMenus(e) {
          if (this.$refs.profile && !this.$refs.profile.contains(e.target)) {
            this.profileMenu = false;
          }
          if(this.$refs.sidebar && this.$refs.menuIcon && !this.$refs.sidebar.contains(e.target) && !this.$refs.menuIcon.contains(e.target)){
            this.mobileNav = false;
          }
        },
    }

}
</script>



<style lang="scss" scoped>

header {
  background-color: #fff;
  padding: 0 25px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .container{
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-right: 25px;
  }
  .link {
    font-weight: 500;
    padding: 0 8px;
    transition: 0.3s color ease;
    &:hover {
      color: #1eb8b8;
    }
  }
  nav {
    display: flex;
    padding: 25px 0;
    .branding {
      display: flex;
      align-items: center;
      .header {
        font-weight: 600;
        font-size: 24px;
        color: #000;
        text-decoration: none;
      }
    }
    .nav-links {
      position: relative;
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-end;
      ul {
        margin-right: 32px;
        .link {
          margin-right: 32px;
        }
        .link:last-child {
          margin-right: 0;
        }
      }
      .profile {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #fff;
        background-color: #303030;
        span {
          pointer-events: none;
        }
        .profile-menu {
          position: absolute;
          top: 60px;
          right: 0;
          width: 250px;
          background-color: #303030;
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
          .info {
            display: flex;
            align-items: center;
            padding: 15px;
            border-bottom: 1px solid #fff;
            .initials {
              position: initial;
              width: 40px;
              height: 40px;
              background-color: #fff;
              color: #303030;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
            }
            .right {
              flex: 1;
              margin-left: 24px;
              p:nth-child(1) {
                font-size: 14px;
              }
              p:nth-child(2),
              p:nth-child(3) {
                font-size: 12px;
              }
            }
          }
          .options {
            padding: 15px;
            .option {
              text-decoration: none;
              color: #fff;
              display: flex;
              align-items: center;
              margin-bottom: 12px;
              .icon {
                width: 18px;
                height: auto;
              }
              p {
                font-size: 14px;
                margin-left: 12px;
              }
              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
    .mobile-user-menu {
      margin-right: 40px;
    }
  }
  .menu-icon {
    cursor: pointer;
    height: 25px;
    width: auto;
  }
  .mobile-nav {
    padding: 20px;
    width: 70%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100%;
    background-color: #303030;
    top: 0;
    left: 0;
    .link {
      padding: 15px 0;
      color: #fff;
    }
  }
  .mobile-nav-enter-active,
  .mobile-nav-leave-active {
    transition: all 1s ease;
  }
  .mobile-nav-enter {
    transform: translateX(-250px);
  }
  .mobile-nav-enter-to {
    transform: translateX(0);
  }
  .mobile-nav-leave-to {
    transform: translateX(-250px);
  }
}
</style>